import M from "materialize-css";
import { useEffect, useState, useSearchParams } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function VinotecaLogin({openModalLoginVinoteca, setOpenModalLoginVinoteca}){

    const navigate = useNavigate();
    
    const [loginButton, setLoginButton] = useState(false);
    const [username, setUserName] = useState("");
    const [pw, setPw] = useState("");
    const [register, setRegister] = useState(false);
    const [nameR, setNameR] = useState("");
    const [usernameR, setUserNameR] = useState("");
    const [pwR, setPwR] = useState("");
    const [nameVinotecaR, setNameVinotecaR] = useState("");
    const [addressVinotecaR, setAddressVinotecaR] = useState("");

    const [loadingRegister, setLoadingRegister] = useState(false);
    const [showConfirmationRegister, setShowConfirmationRegister] = useState(false);
    const [registerMessage, setRegisterMessage] = useState("");

    const [errorMessage, setErrorMessage] = useState([
        {
        color:"",
        msg:"",
        show:false
        }
    ]);

    const api_url = "https://pampawineclub.com:8089";
    const openLoginVinoteca = (modal) => {
        var elems = document.getElementById("LoginVinoteca");
        const options = {
            preventScrolling:false,
            dismissible:true
        }
        var instances = M.Modal.init(elems,options);
        instances.open();
    }

    if(openModalLoginVinoteca==true){
        setOpenModalLoginVinoteca(false);
        openLoginVinoteca();
    }



    const checkCreds = () =>{
        setPw("");
        axios({
            method:'POST',
            url:api_url+'/api/loginVinoteca',
            data:{
                username:username,
                pw:pw
            }
        }).then(res => {
            
            if (res.data.status == 200) {
                setErrorMessage([{color:"",msg:"Ingreso exitoso",show:false}]);
                newToken(res.data.token);
            }else if(res.data.verified == false){
                setErrorMessage([{color:"",msg:"Seguimos verificando la cuenta. Cuando haya sido verificada, le enviaremos un correo electronico. Gracias.",show:true}]);
            }else{
                setErrorMessage([{color:"red",msg:"Usuario/clave incorrecto",show:true}]);
            }
        }).then(res => {});

        const newToken = async n_token => {
            await localStorage.setItem('token', n_token);
            await navigate('/vinotecas');
        }
    }

    const RegisterMessage = () => {
        return(
            <div>{registerMessage}</div>
        )
    }

    const registerVinoteca = () => {
        setLoadingRegister(true);
         
            axios({
                method:'POST',
                url:api_url+'/api/registerVinoteca',
                data:{
                    name:nameR,
                    usernameR:usernameR,
                    pw: pwR,
                    nameVinoteca:nameVinotecaR,
                    addressVinoteca:addressVinotecaR
                }
            }).then(res => {
                if (res.data.status == 200) {
                    setLoadingRegister(false);
                    setShowConfirmationRegister(true);
                    setRegisterMessage("Se registro correctamente. Le estaremos enviando un correo electronico para confirmar su usuario. Gracias.");
                }else{
                    setLoadingRegister(false);
                    setShowConfirmationRegister(true);
                    setRegisterMessage(res.data.msg);
                }
            }).then(res => {});
    
           
            const newToken = async n_token => {
                //await setToken(n_token);
                await localStorage.setItem('token', n_token);
                await navigate('/vinotecas');
            }
            
    }

    return(
        <>
            <div  className="card card_main_products" style={{height:'auto'}}>
                <div id={"LoginVinoteca"} className="modal modal_1" href="#modal1" style={{color:'#0d47a1',padding:'10px'}}>
                    <div className="modal-content modal_1" style={{ color: '#0d47a1' }}>
                        {!register ? 
                        <div>
                            <div class="row">
                                <div class="col s12 m12 l12" style={{ textAlign: 'center', fontSize: '20px' }}>
                                    <p>Usuario Vinoteca</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s12">
                                    <input placeholder="" id={"usuario"} type="text" class="validate" value={username} onChange={e => setUserName(e.currentTarget.value)} />
                                    <label for="usuario">Correo electronico</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-field col s12">
                                    <input type="password" id={"password"} class="validate" value={pw} onChange={e => setPw(e.currentTarget.value)} />
                                    <label for="password">Clave</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12" style={{ textAlign: 'center' }}>
                                    {errorMessage[0].show && <p style={{color:errorMessage[0].color}}>{errorMessage[0].msg}</p>}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12" style={{ textAlign: 'center' }}>
                                    <a class="waves-effect waves-light btn-small" onClick={e => checkCreds()} disabled={!username || !pw}>Ingresar</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12" style={{ textAlign: 'center' }}>
                                    <a style={{ cursor: 'pointer' }} onClick={e => setRegister(true)}>Registrarse</a>
                                </div>
                            </div>
                        </div>
                            : <div>
                                <div class="row">
                                    <div class="col s12 m12 l12" style={{ textAlign: 'center', fontSize: '20px' }}>
                                        <p>Registrarse como vinoteca</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div id={'nameRDiv'} class="input-field col s12">
                                        <input placeholder="" id={"nameR"} type="text" class="validate" value={nameR} onChange={e => setNameR(e.currentTarget.value)} />
                                        <label for="nameR">Nombre</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-field col s12">
                                        <input placeholder="" id={"usuarioR"} type="text" class="validate" value={usernameR} onChange={e => setUserNameR(e.currentTarget.value)} />
                                        <label for="usuarioR">Correo electronico</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-field col s12">
                                        <input type="password" id="passwordR" class="validate" onChange={e => setPwR(e.target.value)} />
                                        <label for="passwordR">Clave</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-field col s12">
                                        <input type="text" id="NameVinotecaR" class="validate" onChange={e => setNameVinotecaR(e.target.value)} />
                                        <label for="NameVinotecaR">Nombre de la vinoteca</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-field col s12">
                                        <input type="text" id="addressVinotecaR" class="validate" onChange={e => setAddressVinotecaR(e.target.value)} />
                                        <label for="addressVinotecaR">Direccion de la vinoteca</label>
                                    </div>
                                </div>
                                {!loadingRegister ? <div class="row">
                                    <div>
                                    <div class="col s12" style={{ textAlign: 'center' }}>
                                        <a class="waves-effect waves-light btn-small" onClick={e => registerVinoteca()} disabled={!usernameR || !pwR || !nameVinotecaR || !addressVinotecaR || !nameR}>Registrarse</a>
                                    </div>
                                </div> <div class="row">
                                    <div class="col s12" style={{ textAlign: 'center' }}>
                                        <a style={{ cursor: 'pointer' }} onClick={e => setRegister(false)}>Ingresar</a>
                                    </div>
                                </div></div> : <div>Registrando..</div>}
                                {!loadingRegister && showConfirmationRegister && <RegisterMessage /> }
                            </div> 
                            }
                    </div>
                </div>
            </div>
        </>
    )
}

export default VinotecaLogin;