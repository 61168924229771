import '../App.css';
import axios from 'axios';
import Navbar from './Navbar';
import Wine from './Wine';
import { useEffect, useState } from 'react';
import { Rings } from 'react-loader-spinner'
//import background from '../img/grape_background.jpg';


//var completeOrder = [];
var totalAmount = 0;
var totalQuantity = 0;

function WineList(){

    const api_url = "https://pampawineclub.com:8089";

    const [wList, setWList] = useState([]);
    const [order, setOrder] = useState(['']);
    const [completeOrder, setCompleteOrder] = useState([]);
    const [orderTotal, setOrderTotal] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [totalOrder, setTotalOrder] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [deleteItem, setDeleteItem] = useState("");
    const [deletedState, setDeletedState] = useState(false);
    const [seeRecommendations, setSeeRecommendations] = useState(false);
    const [loader, setLoader] = useState(false);
    //var completeOrder = [];

    const Loader = () => {
        if(loader==true){
            return(
                <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <Rings
                height="80"
                width="80"
                color="#4fa94d"
                radius="6"
                wrapperStyle={{textAlign:"center"}}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
                />
                </div>
            )
        }else{

        }
        
    }

    useEffect(()=>{

        setLoader(true);
        axios({
            method:'GET',
            url:api_url+'/api/get_wine_list',
            headers:{
            },
        }).then(res => {
            setLoader(false);
            setWList(res.data);
        });
    },[0]);

    function updateTotalPrice(){
        setOrderTotal(orderTotal + 10);
        //trying();
    }

    
    function addOne(){
        totalAmount = 0;
        totalQuantity = 0;
        completeOrder.map((wine_2, index) => {
            totalAmount = totalAmount + (wine_2.wine.price * wine_2.amount_2);
            totalQuantity = totalQuantity + wine_2.amount_2;
        });
        setOrderTotal(totalAmount);
        setQuantity(totalQuantity);
    }

    function findAll(text){
        setSearchText(text);
    }

    

    function checkOrder(){
    }

    return(
     <div style={{textAlign:'center'}}>
        <div style={{backgroundImage:`url('https://www.pampawineclub.com/img/bodega.jpg')`,backgroundAttachment:'fixed',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'fixed'}}>
      <div className="App" style={{backgroundImage:`url('https://www.pampawineclub.com/img/bodega.jpg')`,backgroundColor:'#FCF4DF'}} >
        <div id={'navDiv'} className="navbar-fixed">
            <Navbar total={orderTotal} addOne={addOne} setOrderTotal={setOrderTotal} quantity={quantity} order={completeOrder} setCompleteOrder={setCompleteOrder} findAll={findAll} deleteItem={deleteItem} setDeleteItem={setDeleteItem} deletedState={deletedState} setDeletedState={setDeletedState} />
        </div>
        <div style={{padding:'10px',color:'#FFFFFD',fontSize:'35px'}}>Lista de vinos.</div>
            <div >
            
            {
                wList.map((wine, index) => {
                    if(wine.name.toUpperCase().includes(searchText.toUpperCase())==true){
                        return(
                            <div key={"id_"+index} id={wine.name} style={{display:'block'}}>
                                <Wine  addWine={addOne} w={wine} completeOrder={completeOrder} checkOrder={checkOrder} deleteItem={deleteItem} setDeleteItem={setDeleteItem} deletedState={deletedState} setDeletedState={setDeletedState}/>
                            </div>
                        )
                    }else{
                        return(
                            <div key={"id_"+index} style={{display:'none'}}>
                                <Wine  addWine={addOne} w={wine} completeOrder={completeOrder} checkOrder={checkOrder} deleteItem={deleteItem} setDeleteItem={setDeleteItem} deletedState={deletedState} setDeletedState={setDeletedState}/>
                            </div>
                        )
                    }
                })
            }
            </div>
            
      </div>
      </div>
      <div className='row' style={{textAlign:'center',margin:'auto'}}><div className='col l12 m12 s12' style={{textAlign:'center',margin:'auto'}}><Loader /></div></div>
     </div>
     
    );

    
}
    
export default WineList;