import logo from './logo.svg';
import Home from './Components/Home';
import { useEffect, useState } from 'react';

function App() {
  return (
    <Home />
  );
}

export default App;
