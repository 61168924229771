import { useEffect, useState, useSearchParams } from 'react';
import WineList from './WineList';
import CurrencyFormat from 'react-currency-format';
import M from "materialize-css";
import axios from 'axios';
import '../App.css';
import { Rings } from 'react-loader-spinner';

function Navbar({total, addOne, setOrderTotal, quantity,order, findAll, setCompleteOrder, deleteItem, setDeleteItem, deletedState, setDeletedState}){
    const [order1, setOrder1] = useState(total);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [promoCode, setPromoCode] = useState("");
    const [verifiedPromoCode, setVerifiedPromoCode] = useState("");
    const [discountCode, setDiscountCode] = useState(0);
    const [validatePromoCode, setValidatePromoCode] = useState(false);
    const [promoValidated, setPromoValidated] = useState(false);
    const [orderDetails, setOrderDetails] = useState(order);
    const queryParameters = new URLSearchParams(window.location.search);
    const listName = queryParameters.get("list");
    const [shippingCost, setShippingCost] = useState(3500);

    const api_url = "https://pampawineclub.com:8089";

    //setOrderTotal(500);
    //console.log();


    const openModal = (modal) => {
        var elems = document.getElementById("Pedido");
        var instances = M.Modal.init(elems);
        instances.open();
        setOrderDetails(order);
        document.getElementById('modal_main').style.display = 'block';
        document.getElementById('confirm_div').style.display = 'none';
        
    }

    const PromoCode = () => {

        if(validatePromoCode==true){
            return(
                <div className="col s12 m12 l12">
                <Rings 
                height="80"
                width="100%"
                color="#4fa94d"
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
                />
                </div>
            )
        }else if(promoValidated==true){
            return(
                <div>
                    <div className="col s12 m2 l2"><b style={{}}>Codigo aplicado!</b></div>
                </div>
            )
        }else{
            return(
                <div>
                <div className="col s12 m2 l2"><div><a onClick={(e) => validate_promo()} class="waves-effect waves-light btn">Validar</a></div>
                </div>
                </div>
            )
        }
    }

    const FinalPrice = () => {
        
        if(discountCode>0){
            return(
                <div>
                    <CurrencyFormat value={total} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} /> (<b>-{discountCode}%</b>) + <CurrencyFormat value={shippingCost} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} /> (Envio) 
                    <div  style={{fontSize:'20px',color:'green',fontWeight:'bold'}}>
                Total: <CurrencyFormat value={((total)-((discountCode*total)/100))+shippingCost} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} />
                </div>
                </div>
                
            )
        }else{
            return(
                <div>
                <CurrencyFormat value={total} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} /> + <CurrencyFormat value={shippingCost} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} /> (Envio) 

                <div  style={{fontSize:'20px',color:'green',fontWeight:'bold'}}>
                Total: <CurrencyFormat value={total+shippingCost} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} /> 
                </div>
                </div>
            )
        }
    }

    const validate_promo = () => {
        setValidatePromoCode(true);
        
        axios({
            method:'POST',
            url:api_url+'/api/validate_promo',
            data:{
                promoCode:promoCode
            }
        }).then(res => {
            if(res.data!="" && res.data!=null){
                setPromoValidated(true);
                setDiscountCode(res.data.discount);
                setVerifiedPromoCode(res.data.code);
            }
            setValidatePromoCode(false);
        }).then(res => {setValidatePromoCode(false)});
    }

    const send_order = () => {
        axios({
            method:'POST',
            url:api_url+'/api/send_order',
            data:{
                name:name,
                phone:phone,
                email:email,
                orderDetails:orderDetails,
                total:total,
                listName,
                promoCode:verifiedPromoCode
            }
        }).then(res => {
            console.log("Order email sent.");
            M.toast({html: 'Hemos recibido el pedido. Nos pondremos en contacto a la brevedad. Gracias!'});
            //instances.close();
            document.getElementById('modal_main').style.display = 'none';
            document.getElementById('confirm_div').style.display = 'block';
            
        });
    }
    const removeItem = (name) => {
        //console.log(name);
        const n = orderDetails;
        setDeletedState(false);
        setDeleteItem(name);
        setOrderDetails(orderDetails => orderDetails.filter(item => {
            return item.wine.name !== name;
        }));
    }

    useEffect(()=>{
        //console.log("Changed "+orderDetails.name);
        setCompleteOrder(orderDetails);
        console.log(order);
        addOne();
    },[orderDetails]);

    useEffect(()=>{
        addOne();
    },[order]);

    useEffect(()=>{
        
    },[deletedState]);


    return(
        <div>
            <nav style={{fontSize:'20px',backgroundColor:'#F3F1EE',maxHeight:'auto',maxWidth:'100%'}}>
                <div className="nav-wrapper">
                    <div className='row'>
                        <div className='col m5 s5 l5' style={{textAlign:'center',margin:'auto'}}>
                            <ul>
                                <li style={{maxWidth:'20%'}}>
                                    <i className="material-icons left teal-text">search</i>
                                </li>
                                <li style={{maxWidth:'80%'}}>
                                    <input onChange={e  => findAll(e.target.value)} placeholder='Buscar' />
                                </li>
                            </ul>
                        </div>
                        <div className='col m7 s7 l7' style={{padding:'0px'}}>
                            <ul className='right'>
                                <li>
                                    <b style={{paddingRight:'0px',color:'#8BD449',fontWeight:'bold'}}>
                                        <CurrencyFormat value={total} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} />
                                    </b>
                                    <a className="btn" style={{paddingTop:'2px',paddingBottom:'2px',paddingLeft:'5px',paddingRight:'3px'}} onClick={() => openModal("Hola")}><i className="material-icons right">shopping_cart</i>Pedir ({quantity})</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div key={"Pedido"} className='col m4 s12 row_3'>
                    <div  className="card card_main_products" style={{height:'auto'}}>
                            <div id={"Pedido"} className="modal modal_1" href="#modal1" style={{color:'#0d47a1',padding:'10px'}}>
                                <div  className="modal-content modal_1" style={{color:'#0d47a1'}}>
                                <div id={"modal_main"} className="address_div" style={{color:'#0d47a1'}}>
                                 <div className="row form_div" >
                                    <div className="input-field input_form col s12 m6 l6">
                                        <i className="material-icons prefix">account_circle</i>
                                        <input onChange={e => setName(e.target.value)} id="first_name" type="text" className="validate" />
                                        <label htmlFor="first_name">Nombre</label>
                                    </div>
                                    <div className="input-field col s12 m6 l6">
                                        <i className="material-icons prefix">phone</i>
                                        <input onChange={e => setPhone(e.target.value)} id="Telefono" type="text" className="validate" />
                                        <label htmlFor="Telefono">Telefono</label>
                                    </div>
                                    <div className="input-field col s12 m12 l12">
                                            <i className="material-icons prefix">email</i>
                                            <input id="email_input" onChange={e => setEmail(e.target.value)} type="text" className="validate" />
                                            <label htmlFor="email_input">Correo electronico</label>
                                    </div>
                                    <div className='row' style={{paddingTop:'10px',paddingBottom:'10px'}}>
                                    <div className="col s12 m3 l3"></div>
                                    <div className="col s12 m4 l4">
                                    <input id={'promo_id'} placeholder='Codigo de promocion' onChange={(e) => setPromoCode(e.target.value)} type="text"  />                    
                                    </div>
                                    <PromoCode key={"fgerg"} />
                                    <div className="col s12 m3 l3"></div>
                                    </div>
                                    <div style={{fontSize:'15px',color:'#1a237e'}}>
                                        {orderDetails.map((order2,index) => {
                                            return(
                                                <div key={"nav_"+index}>
                                                <div className='row'>
                                                    <div className='col s6 m6 l6'>
                                                       <b>{order2.wine.name}</b>  
                                                    </div>
                                                    <div className='col s4 m4 l4'>
                                                        (<CurrencyFormat value={order2.wine.price} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} />
                                                        {" x "+order2.amount_2}) = <CurrencyFormat value={order2.wine.price*order2.amount_2} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} />
                                                    </div>
                                                    <div className='col s2 m2 l2'>
                                                        <button onClick={e => removeItem(order2.wine.name)} style={{border:'none',background:'none'}}>
                                                        <i style={{cursor:'pointer'}} className="material-icons prefix">delete_forever</i>
                                                        </button>
                                                    </div>
                                                </div>
                                                 </div>
                                            )
                                        }) }
                                        <div>
                                        </div>
                                        <FinalPrice />
                                    </div>
                                 </div>
                                 <div className="row form_div" style={{height:'auto'}}>
                                    <div className="input-field input_form col s12 m12 l12" style={{color:'#212121'}}>
                                        Una vez hecho el pedido, le enviaremos un whatsapp de confirmacion. Los pagos se realizan contra entrega en efectivo o transferencia bancaria.
                                    </div>
                                    <a className=" btn" onClick={(e) => send_order()} disabled={!name || !phone || !email || orderDetails.length == 0}><i className="material-icons right">send</i>Enviar</a>
                                 </div>   
                                </div>
                                <div id={'confirm_div'} style={{display:'none'}}>Gracias!
                                    <div className="input-field input_form col s12 m12 l12" >
                                    Una vez hecho el pedido, le enviaremos un whatsapp de confirmacion. Los pagos se realizan contra entrega en efectivo o transferencia bancaria.
                                    </div>
                                </div>
                                </div>
                            </div>
                    </div>
                 </div>
        </div>
    )
}

export default Navbar;