import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import  App  from './App';
import  WineList  from './Components/WineList';
import  WineListVinoteca  from './Components/Vinotecas/WineListVinoteca';

function Main() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} exact />
                <Route path="/list" element={<WineList />} exact />
                <Route path="/vinotecas" element={<WineListVinoteca />} exact />
            </Routes>
        </Router>
    );
}

export default Main;