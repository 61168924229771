import { useEffect, useState } from 'react';
import * as wineList from './WineList';
import CurrencyFormat from 'react-currency-format';
import '../App.css';

//var completeOrder = [];

const Wine = ({ addWine, w, completeOrder, checkOrder, deleteItem, setDeleteItem, deletedState, setDeletedState }) => {

    const [wine, setWine] = useState(w);
    const [amount, setAmount] = useState(0);
    const [inOrder, setInOrder] = useState(false);


    useEffect(() => {
        if (amount == 1 && inOrder == false) {
            setInOrder(true);
            completeOrder.push({
                wine,
                amount_2: amount
            });
            addWine();
            checkOrder();
        } else if (amount >= 1) {
            completeOrder.map((item, index) => {
                if (w.name == item.wine.name) {
                    completeOrder[index].amount_2 = amount;
                }
            })
            addWine();
            checkOrder();
        } else {
            setInOrder(false);
            completeOrder.map((item, index) => {
                if (w.name == item.wine.name) {
                    completeOrder.splice(index, 1);
                    checkOrder();
                }
            });
            addWine();
        }

    }, [amount]);

    useEffect(() => {
        if (deleteItem == wine.name) {
            //console.log(wine.name);
            setAmount(0);
            setDeletedState(true);
        }
    }, [completeOrder]);

    const addOne = () => {
        setAmount(amount + 1);
    }
    const removeOne = () => {
        setAmount(amount - 1);
    }
    const recommended = (recommended) => {
        if (recommended == 1) {
            return (
                <div className='' >
                    <div style={{ fontSize: '15px' }}>
                        <div className='col l12 s12' style={{ padding: '30px', backgroundColor: 'orange', fontWeight: 'bold', color: 'white', textAlign: 'center' }}>Recomendado</div>
                        <div style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px', borderStyle: 'solid', borderColor: 'orange', marginTop: '0px', padding: '10px', background: 'linear-gradient(90deg, rgba(232,227,209,1) 0%, rgba(209,204,185,1) 60%, rgba(196,188,166,1) 100%)', backgroundColor: '#F0E4C7' }} className="row card ">
                            <div style={{ color: '#2D2B27' }}>
                                <div className='col l5 m4 s12' style={{ fontStyle: 'italic' }}>{wine.name}{vivino(wine.vivino)}</div>
                                <div className='col l3 m4 s12' style={{ fontWeight: 'bold' }}><CurrencyFormat value={wine.price} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} /></div>
                                
                                <div className='col l3 m4 s12' style={{padding:'10px'}}>
                                    <button className="btn-floating btn-small  red" disabled={amount < 1} onClick={e => removeOne()}><i className="material-icons">remove</i></button>
                                    <b id={'amount_'} style={{ padding: '5px' }}>{amount}</b>
                                    <button className="btn-floating btn-small  green" onClick={e => addOne()}><i className="material-icons">add</i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='' >
                    <div style={{ fontSize: '15px' }}>
                        <div style={{ padding: '10px', background: 'linear-gradient(90deg, rgba(232,227,209,1) 0%, rgba(209,204,185,1) 60%, rgba(196,188,166,1) 100%)', backgroundColor: '#F0E4C7' }} className="row card ">
                            <div style={{ color: '#2D2B27' }}>
                                <div className='col l5 m4 s8' style={{ padding:'5px', fontStyle: 'italic' }}>{wine.name}{vivino(wine.vivino)}</div>
                                <div className='col l3 m4 s4' style={{ padding:'5px', fontWeight: 'bold' }}><CurrencyFormat value={wine.price} displayType={'text'} decimalSeparator={','} thousandSeparator={'.'} prefix={'$'} /></div>
                                
                                <div className='col l3 m4 s12' style={{padding:'5px'}}>
                                    <button className="btn-floating btn-small  red" disabled={amount < 1} onClick={e => removeOne()}><i className="material-icons">remove</i></button>
                                    <b id={'amount_'} style={{ padding: '5px' }}>{amount}</b>
                                    <button className="btn-floating btn-small  green" onClick={e => addOne()}><i className="material-icons">add</i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    const vivino = (score) => {
        if (score != "" && score !=0 && score !=null) {
            return (
                <span  style={{fontSize:'15px'}}> (<b style={{ color: '#C00238' }}>Vivino {score}</b>)</span>
            )
        } else {
            return (
                <div className='col l1 s12'></div>
            )
        }
    }


    return (
        <div style={{ paddingRight: '5px', paddingLeft: '5px' }}>
            {recommended(wine.recommended)}
        </div>
    )

}
export default Wine;