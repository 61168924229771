import logo from '../img/logo_resize.png';
import M from "materialize-css";

function NavbarHome({setOpenModalLoginVinoteca}){

  
  //componentDidMount() {
    var elems = document.querySelectorAll('.sidenav');
    var instances = M.Sidenav.init(elems);

    return(
        <>
        <div class="navbar-fixed">
        <nav style={{backgroundColor:'white'}}>
    <div class="nav-wrapper">
    <a href="/" class="brand-logo" style={{paddingLeft:'30px',paddingRight:'30px'}}><img style={{height:'50px', width:'50px'}} src={logo} /></a>
      <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons" style={{color:'brown'}}>menu</i></a>
      <ul class="right hide-on-med-and-down">
          <li><a style={{color:'#71838E'}} href="#services">Nosotros</a></li>
          <li><a style={{color:'#71838E'}} href="/list">Lista de vinos</a></li>
          <li><a style={{color:'#71838E'}} href="/#services">Servicios</a></li>
          <li><a style={{color:'#71838E'}} href="/#" onClick={e => setOpenModalLoginVinoteca(true)}>Vinotecas</a></li>
          <li><a style={{color:'#71838E'}} href="#contact">Contacto</a></li>
      </ul>
    </div>
  </nav>
  </div>

  <ul class="sidenav" id="mobile-demo">
  <li><a style={{color:'#71838E'}} href="#services">Nosotros</a></li>
          <li><a style={{color:'#71838E'}} href="/list">Lista de vinos</a></li>
          <li><a style={{color:'#71838E'}} href="/#services">Servicios</a></li>
          <li><a style={{color:'#71838E'}} href="/#" onClick={e => setOpenModalLoginVinoteca(true)}>Vinotecas</a></li>
          <li><a style={{color:'#71838E'}} href="#contact">Contacto</a></li>
  </ul>
        </>
        
    )
}

export default NavbarHome;