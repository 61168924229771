
import axios from 'axios';
import NavbarHome from './NavbarHome';
import VinotecaLogin from './VinotecaLogin';
import { useEffect, useState, useSearchParams } from 'react';
import { useNavigate, useLocation  } from "react-router-dom";
import variedad_vinos from '../img/seleccion_vinos.jpg';
import degustacion from '../img/degustacion.jpg';
import envio from '../img/delivery.jpg';
import '../app_home.css';


function Home(){

    const search = useLocation().search;
    const listNameSearch = new URLSearchParams(search);

    const [listName, setListName] = useState("");
    const [openModalLoginVinoteca, setOpenModalLoginVinoteca] = useState(false);

    useEffect(() => {
        setListName(listNameSearch.get('list'));
    },[listName]);

    const navigate = useNavigate();

    if(listName!="" && listName != 'null' && listName != null){
        navigate("/list/?list="+listName);
        console.log(listName);
    }

    return(
        <>
            <NavbarHome setOpenModalLoginVinoteca={setOpenModalLoginVinoteca} />
            <body id="page-top" className='body2'>
                <header class="masthead">
                    <div class="container1 px-4 px-lg-5 h-100">
                        <div class="row1 gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                            <div class="col-lg-8 align-self-end">
                                <h1 class="text-white font-weight-bold">Todo lo que necesitas del vino en un mismo lugar</h1>
                                <hr class="divider" />
                            </div>
                            <div class="col-lg-8 align-self-baseline">
                                <p class="text-white-75 mb-5">Realiza tu pedido al mejor precio y/o crea tu propia cata con nuestro creador de catas automatico y te enviamos todo lo que necesites para disfrutar de la mejor experiencia</p>
                                <div><a class="btn btn-primary btn-xl" href="/list">Ver lista de vinos</a></div>
                            </div>
                        </div>
                    </div>
                </header>
                
                <section class="page-section" id="about" style={{ backgroundColor: 'white' }}>
                    <div class=" px-4 px-lg-5" style={{ color: '#71838E' }}>
                        <div class="row1 gx-4 gx-lg-5 justify-content-center">
                            <div class="col-lg-8 text-center">
                                <h2 class=" mt-0">Tenemos lo que vos o tu empresa necesita!</h2>
                                <hr class="divider divider-light" />
                                <p class="text-75 mb-4" style={{ color: '#71838E' }}>En pampa wine club podes generar tus degustaciones automaticamente o a seleccion manual de uno de nuestros expertos. En Pampa Wine Club queremos que los clientes tengan la mejor experiencia y nos encargamos de todo, desde los vinos hasta las copas, todo en el mismo lugar y al mejor precio!</p>
                                <a class="btn btn-light btn-xl" href="/list">Hace tu pedido!</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="page-section" id="services" style={{ color: 'white', textAlign: 'center' }}>
                    <div class="container1 px-4 px-lg-5">
                        <h2 class="text-center mt-0">Nuestros servicios</h2>
                        <hr class="divider" />
                        <div class="row1 gx-4 gx-lg-5" style={{ color: 'white' }}>
                            <div class="col-lg-3 col-md-6 col-lg-3 text-center">
                                <div class="mt-5">
                                    <div class="mb-2"><i class="bi-card-checklist fs-1 text-primary"></i></div>
                                    <h3 class="h4 mb-2">Seleccion de vinos</h3>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-lg-3 text-center">
                                <div class="mt-5">
                                    <div class="mb-2"><i class="bi-handbag fs-1 text-primary"></i></div>
                                    <h3 class="h4 mb-2">Creacion de degustaciones</h3>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-lg-3 text-center">
                                <div class="mt-5">
                                    <div class="mb-2"><i class="bi-box-seam fs-1 text-primary"></i></div>
                                    <h3 class="h4 mb-2">Envios a GBA y CABA</h3>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-lg-3 text-center">
                                <div class="mt-5">
                                    <div class="mb-2"><i class="bi-cash-coin fs-1 text-primary"></i></div>
                                    <h3 class="h4 mb-2">Asesoramiento y venta por mayor</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div id="portfolio" style={{ paddingBottom: '0px' }}>
                        <div class="container1-fluid p-0" >
                            <div class="row1 g-0" >
                                <div class="col-lg-4 col-sm-12" >
                                    <a class="portfolio-box" href="#" blue title="Project Name">
                                        <img class="img-fluid img_main" src={variedad_vinos} alt="..." />
                                        <div class="portfolio-box-caption">
                                            <div class="project-category text-white-50">Vinos</div>
                                            <div class="project-name">Seleccion a medida</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-lg-4 col-sm-12">
                                    <a class="portfolio-box" href="#" title="Project Name">
                                        <img class="img-fluid img_main" src={degustacion} alt="..." />
                                        <div class="portfolio-box-caption">
                                            <div class="project-category text-white-50">Degustaciones</div>
                                            <div class="project-name">Adaptado al presupuesto</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-lg-4 col-sm-12">
                                    <a class="portfolio-box" href="#" title="Project Name">
                                        <img className="img-fluid img_main" src={envio} alt="..." />
                                        <div class="portfolio-box-caption">
                                            <div class="project-category text-white-50">Envios</div>
                                            <div class="project-name">CABA y GBA</div>
                                        </div>
                                    </a>
                                </div>
                                <section class="page-section bg-dark text-white" >
                                    <div class="container1 px-4 px-lg-5 text-center">
                                        <h2 class="mb-4">Degustaciones. Nos encargamos de todo!</h2>
                                        <p>En Pampa Wine Club nos encargamos de enviar todo lo necesario para crear la degustacion, sea para un evento social, personal o empresarial. Nosotros nos ocupamos de enviar la mejor seleccion de vinos, copas, abridores, aereadores y mas! Todo adaptado a tu presupuesto.</p>
                                        <a class="btn btn-light btn-xl" href="/list">Crear degustacion</a>
                                    </div>
                                </section>
                                <section class="page-section" id="contact">
                                    <div class="container1 px-4 px-lg-5">
                                        <div class="row1 gx-4 gx-lg-5 justify-content-center">
                                            <div class="col-lg-8 col-xl-6 text-center">
                                                <h2 class="mt-0" style={{ color: '#ffffff' }}>Contacto</h2>
                                                <hr class="divider" />
                                                <p class="text-muted mb-5">Contanos que necesitas y uno de nuestros asesores se pondra en contacto cuanto antes!</p>
                                            </div>
                                        </div>
                                        <div class="row1 gx-4 gx-lg-5 justify-content-center mb-5">
                                            <div class="col-lg-6">

                                                <div class="form-floating mb-3">
                                                    <input class="form-control" id="name" type="text" placeholder="Enter your name..." data-sb-validations="required" />
                                                    <label for="name">Nombre</label>
                                                    <div class="invalid-feedback" data-sb-feedback="name:required">Nombre invalido.</div>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <input class="form-control" id="email" type="email" placeholder="name@example.com" data-sb-validations="required,email" />
                                                    <label for="email">Correo electronico</label>
                                                    <div class="invalid-feedback" data-sb-feedback="email:required">Email invalido.</div>
                                                    <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <input class="form-control" id="phone" type="tel" placeholder="(011) 3456-7890" data-sb-validations="required" />
                                                    <label for="phone">Telefono</label>
                                                    <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <textarea class="form-control" id="message" type="text" placeholder="Enter your message here..." style={{ height: "10rem" }} data-sb-validations="required"></textarea>
                                                    <label for="message">Mensaje</label>
                                                    <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                                                </div>

                                                <div class="d-none" id="submitSuccessMessage">
                                                    <div class="text-center mb-3">
                                                        <div class="fw-bolder">Form submission successful!</div>
                                                        To activate this form, sign up at
                                                        <br />
                                                        <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                                                    </div>
                                                </div>

                                                <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error sending message!</div></div>
                                                <div class="d-grid"><button class="btn btn-primary btn-xl disabled" id="submitButton" type="submit">Submit</button></div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <footer class="bg-light py-5">
                                    <div class="container1 px-4 px-lg-5"><div class="small text-center" style={{ color: '#71838E' }}>Copyright &copy; 2024 - pampawineclub.com</div></div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </section>
                <VinotecaLogin openModalLoginVinoteca={openModalLoginVinoteca} setOpenModalLoginVinoteca={setOpenModalLoginVinoteca}/>
            </body>
            
        </>
    )
}

export default Home;